import React from 'react';
import {
  useReceiptView,
  usePrice,
  ReceiptQRCode,
  CartViewProduct,
} from '@fingermarkglobal/cringer.components';

import Leaf from '../../assets/images/leaf.svg';

const CustomReceiptView = () => {
  const {
    cart,
    receiptPaymentData,
    receiptDetailsData,
    t,
    order,
    settings,
    showAddonAvailability,
    showTxnId,
    payment,
  } = useReceiptView();

  const { formatPrice } = usePrice();

  return (
    <div data-test="receipt-page" className="flex flex-col items-center w-full h-full">
      <div className="grid items-center w-full grid-cols-2 px-8 mt-6 h-28 bg-accessory">
        <div className="text-5xl font-bold uppercase"> {t('receiptTitle')} </div>
        <div className="flex items-center justify-end">
          <Leaf className="w-12 ml-8 mr-4" />
          <div className="text-3xl font-bold text-leaf-green">{t('receiptEcoMessage')}</div>
        </div>
      </div>

      <div className="flex flex-row items-center justify-center w-full mt-6 mb-6 text-4xl">
        {t('orderQrCodeInstructions')}
      </div>

      <div className="flex flex-row items-center justify-center w-full pt-2 text-5xl font-bold">
        {t('orderTitle')}
      </div>
      <div className="grid w-full pb-4 pl-8 pr-8">
        <div className="grid grid-cols-4 border shadow-lg">
          <dl className="grid grid-cols-3 col-span-3 p-4 text-3xl">
            <dt>{t('orderNumberLabel')}</dt>
            <dd className="col-span-2 text-5xl font-bold">{order.orderNumber}</dd>

            <dt> {t('orderNameLabel')}</dt>
            <dd className="col-span-2 font-bold">
              {receiptDetailsData?.customer?.firstName} {receiptDetailsData?.customer?.lastName}
            </dd>

            <dt> {t('orderPhoneLabel')}</dt>
            <dd className="col-span-2 font-bold">{receiptDetailsData.customer.phoneNumber}</dd>

            <dt>{t('orderTypeLabel')}</dt>
            <dd className="col-span-2 font-bold">{receiptDetailsData.orderType}</dd>

            <dt>{t('orderPlacedLabel')}</dt>
            <dd className="col-span-2 font-bold">{receiptDetailsData.datetime}</dd>
          </dl>

          <div className="grid justify-end pt-4 pr-4">
            <div className="items-end w-full row-span-4">
              <ReceiptQRCode size="250x250" />
            </div>
            <div className="grid justify-center w-full row-span-1 text-2xl">
              {t('orderCodeLabel')}
            </div>
          </div>
        </div>
      </div>

      <div className="grid w-full pb-4 pl-8 pr-8">
        <dl className="grid grid-cols-4 p-4 text-3xl border shadow-lg">
          <dt className="col-span-1">{t('receiptPaymentRestaurantName')}</dt>
          <dd className="col-span-3 font-bold">{receiptPaymentData.storeName}</dd>

          <dt className="col-span-1">{t('kioskLabel')}</dt>
          <dd className="col-span-3 font-bold">{order.settings.kiosk.device_name}</dd>

          <dt className="col-span-1">{t('receiptPaymentRestaurantAddress')}</dt>
          <dd className="col-span-3 font-bold">{settings.storeAddress}</dd>
        </dl>
      </div>

      <div className="grid w-full pb-4 pl-8 pr-8">
        {cart &&
          cart.map(product => (
            <CartViewProduct
              product={product}
              key={product.uid}
              t={t}
              boxClassName="shadow-lg"
              formatPrice={formatPrice}
              showAddonAvailability={showAddonAvailability}
            />
          ))}
      </div>

      <div className="grid w-full pb-4 pl-8 pr-8">
        <dl className="grid grid-cols-4 p-4 text-3xl border shadow-lg">
          <span className="col-span-4">
            {t('receiptPaymentItemQuantity')} {receiptPaymentData.productQuantity}
          </span>
          <dt className="col-span-3">{t('receiptPaymentSubTotal')}</dt>
          <dd className="grid justify-end font-bold">
            {formatPrice({ price: receiptPaymentData.total - receiptPaymentData.taxes })}
          </dd>
          <dt className="col-span-3">{t('receiptPaymentTaxTotal')}</dt>
          <dd className="grid justify-end font-bold">
            {formatPrice({ price: receiptPaymentData.taxes })}
          </dd>
          <dt className="col-span-3">{t('receiptPaymentTotal')}</dt>
          <dd className="grid justify-end font-bold">
            {formatPrice({ price: receiptPaymentData.total })}
          </dd>
        </dl>
      </div>

      <div className="flex flex-row items-center justify-center w-full pt-2 mb-2 text-5xl font-bold">
        {t('paymentTitle')}
      </div>

      <div className="grid w-full pb-4 pl-8 pr-8">
        <dl className="grid grid-cols-4 p-4 text-3xl border shadow-lg">
          <dt className="col-span-1">{t('paymentTypeLabel')}</dt>
          <dd className="col-span-3 font-bold">{order.paymentType}</dd>
          <dt className="col-span-1">{t('transactionIdLabel')}</dt>
          <dd className="col-span-3 font-bold">
            {showTxnId
              ? payment?.response?.transInfo?.approvalCode || order.transactionId
              : order.transactionId}
          </dd>
        </dl>
      </div>
    </div>
  );
};

export { CustomReceiptView };
