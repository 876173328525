import React, { useMemo } from 'react';

import {
  BannerWarning,
  PaymentMethods,
  LocaleSelection,
  HomeCustomisation,
  PrimaryButtonLarge,
} from '@fingermarkglobal/cringer.components';

import Logo from '../../../assets/images/logo.svg';
import WashHandsImage from '../../../assets/images/wash-hands.png';
import DefaultHeroImage from '../../../assets/images/bucket.png';
import DefaultBackgroundImage from '../../../assets/images/BG.png';

const SecondaryHome = ({
  t = () => {},
  card,
  cash,
  icons,
  className = '',
  languages = [],
  healthMsg = true,
  primaryAction,
  isCurrentLang,
  secondaryAction,
  onChangeLanguage = () => {},
  accessibilityEnabled = false,
  showTermsAndConditionsButton = false,
  setShowTermsAndConditionsModal = () => {},
}) => {
  const titleClass = useMemo(() => {
    return isCurrentLang('uae') ? 'text-8xl' : 'text-12xl';
  }, [isCurrentLang]);

  const subTitleClass = useMemo(() => {
    return isCurrentLang('uae') ? 'text-7xl' : 'text-9xl';
  }, [isCurrentLang]);

  const isBordered = !!(icons?.length || card);

  return (
    <HomeCustomisation.Provider
      fallback={{
        background: {
          image: DefaultBackgroundImage,
        },
        hero: {
          image: DefaultHeroImage,
        },
      }}
    >
      <HomeCustomisation.Background />

      <div
        data-test="secondary-home"
        className={`z-10 flex flex-col justify-between items-center h-full w-full overflow-y-hidden  ${className}`}
      >
        <div
          data-test="home-upper"
          className="flex flex-col items-center justify-start w-full h-full"
        >
          <PaymentMethods
            t={t}
            card={card}
            cash={cash}
            Logo={Logo}
            icons={icons}
            enableLogo={!accessibilityEnabled}
            className={`px-12 py-4 text-7xl ${isBordered ? 'border-2 border-black' : ''}  rounded ${
              isBordered && !accessibilityEnabled ? 'mt-8' : ''
            }`}
          />

          <div className="flex flex-col items-center justify-center mt-16 leading-none">
            <p className={`${titleClass} text-black font-extrabold`} data-test="home-title">
              {t('homeTitle')}
            </p>

            <p className={`${subTitleClass} text-black font-extrabold`} data-test="home-subtitle">
              {t('homeSubtitle')}
            </p>
          </div>

          {!accessibilityEnabled && <HomeCustomisation.HeroImage />}
        </div>

        <div
          data-test="home-lower"
          className="flex flex-col-reverse items-center justify-start w-full h-full"
        >
          {showTermsAndConditionsButton && (
            <BannerWarning
              data-test="banner-terms"
              t={t}
              width="109"
              className="w-4/5 mb-2"
              title={t('homeTermsAndConditionsBannerWarningTitle')}
              description={t('homeTermsAndConditionsBannerWarningDescription')}
              additionalDescription={t('homeTermsAndConditionsBannerWarningAdditionalDescription')}
              additionalDescriptionAction={() => setShowTermsAndConditionsModal(true)}
            />
          )}

          {healthMsg && (
            <BannerWarning
              t={t}
              width="109"
              className="w-4/5 mb-2"
              data-test="banner-health"
              image={WashHandsImage}
            />
          )}

          <div
            data-test="home-services"
            className={`flex items-stretch justify-center w-4/5 ${
              healthMsg ? 'mb-16' : 'mb-48'
            } space-x-16`}
          >
            {!!secondaryAction && (
              <PrimaryButtonLarge
                onClick={secondaryAction}
                className="w-1/2"
                data-test="button-secondary"
              >
                {t('homeSecondaryAction')}
              </PrimaryButtonLarge>
            )}

            {!!primaryAction && (
              <PrimaryButtonLarge onClick={primaryAction} className="w-1/2">
                {t('homePrimaryAction')}
              </PrimaryButtonLarge>
            )}
          </div>
        </div>

        <div className="flex justify-center w-full mt-8 mb-28 md:mb-0" data-test="home-footer">
          <LocaleSelection languages={languages} onChangeLanguage={onChangeLanguage} />
        </div>
      </div>
    </HomeCustomisation.Provider>
  );
};

export { SecondaryHome };
