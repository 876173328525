/* eslint-disable max-lines */
// Routes is for the applications workflow definitions.
import React from 'react';
import Logo from './src/assets/images/logo.svg';

import { RouteBuilder, base } from '@fingermarkglobal/router';

// Custom views
import { CustomHomeView } from './src/views/home';
import { CustomBeaconView } from './src/views/beacon';
import { CustomReceiptView } from './src/views/receipt';

// Custom templates
import { CustomDefaultTemplate } from './src/templates/default';

import { Loading } from '@fingermarkglobal/kfc-styles';

import { version } from './package.json';
import { Warning } from './src/components/stateless/warning';

import mockSettings from './mock-settings.json';

import { CardPaymentViewProvider, QRCardPaymentView } from '@fingermarkglobal/cringer.components';

const LogoComponent = () => <Logo data-test="logo" className="absolute top-0 w-auto h-64 mt-32" />;

const config = {
  ...base,
  home: {
    ...base.home,
    View: CustomHomeView,
    config: {
      ...base.home.config,
      provider: {
        allowedLanguages: ['vnm', 'en'],
      },
    },
  },
  beacon: {
    ...base.beacon,
    View: CustomBeaconView,
    config: {
      ...base.beacon.config,
      provider: {
        primaryPath: '/categories',
        secondaryPath: '/reset',
      },
      template: {
        headerEnabled: false,
      },
    },
  },
  coupon: {
    ...base.coupon,
    config: {
      ...base.coupon.config,
      provider: {
        hideDisabledButtons: true,
      },
    },
  },
  refund: {
    ...base.refund,
    config: {
      ...base.refund.config,
      provider: {
        qrCodeSize: '300x300',
      },
    },
  },
  category: {
    ...base.category,
    config: {
      ...base.category.config,
      provider: {
        displayDescription: false,
        showCategoriesHeader: true,
        showComboSizeSelection: false,
        hideDisabledButtons: true,
      },
    },
  },
  categories: {
    ...base.categories,
    config: {
      ...base.categories.config,
      provider: {
        hideDisabledButtons: true,
      },
    },
  },
  customer: {
    ...base.customer,
    config: {
      ...base.customer.config,
      template: {
        ...base.customer.config.template,
        footerEnabled: true,
      },
      provider: {
        displayRequiredLabel: false,
        showPrimaryAction: true,
        showEmail: false,
        showAsteriskOnRequiredFields: true,
        emailCodes: '@LIVE.COM @MAIL.RU @GMAIL.COM ',
        isFullNameRequired: true,
        isFirstNameRequired: true,
        isPhoneNumberRequired: true,
        showFullNameField: true,
        showPhoneNumberField: true,
        fullNameMaxLength: 10,
      },
    },
  },
  admin: {
    ...base.admin,
    config: {
      ...base.admin.config,
      provider: { version },
    },
  },
  validate: {
    ...base.validate,
    config: {
      ...base.validate.config,
      provider: { image: LogoComponent, isLogoEnabled: true },
    },
  },
  confirmation: {
    ...base.confirmation,
    config: {
      ...base.confirmation.config,
      provider: { image: LogoComponent, isLogoEnabled: true },
    },
  },
  card: {
    url: '/payment',
    View: QRCardPaymentView,
    Provider: CardPaymentViewProvider,
    config: {
      template: {
        timeoutEnabled: false,
        headerEnabled: false,
        footerEnabled: false,
        templateOverride: true,
      },
      provider: { nextPath: '/checkout' },
    },
  },
  receipt: {
    ...base.receipt,
    config: {
      ...base.receipt.config,
      provider: {
        showTxnId: true,
      },
    },
    View: CustomReceiptView,
  },
  combo: {
    ...base.combo,
    config: {
      ...base.combo.config,
      provider: {
        hideDisabledButtons: true,
      },
    },
  },
  composed: {
    ...base.composed,
    config: {
      ...base.composed.config,
      provider: {
        showCategoriesHeader: true,
        hideDisabledButtons: true,
      },
    },
  },
  cart: {
    ...base.cart,
    config: {
      ...base.cart.config,
      provider: {
        showCategoriesHeader: true,
        hideDisabledButtons: true,
      },
    },
  },
  lock: {
    ...base.lock,
    config: {
      ...base.lock.config,
      template: {
        ...base.lock.config.template,
        timeoutEnabled: false,
      },
    },
  },
  loyalty: {
    ...base.loyalty,
    config: {
      ...base.loyalty.config,
    },
  },
  offers: {
    ...base.offers,
    config: {
      ...base.offers.config,
    },
  },
};

const Routes = () => (
  <RouteBuilder
    Template={CustomDefaultTemplate}
    root={{
      ErrorComponent: Warning,
      resolverProps: { Loading, isRetryActive: true },
      overwriteSettings: mockSettings,
    }}
    config={config}
  />
);

export { Routes };
